import React, { useState } from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './TextBlock.module.scss';

import RichText from '../RichText';
import Button from '../Button';
import Contact from '../Contact';
import LabeledIcon from '../LabeledIcon';
import ShareModal from '../ShareModal';

const TextBlock = ({
    heading,
    preamble,
    blockLink,
    blockButtonLink,
    mainBody,
    contact,
    linksHeading,
    links,
}) => {
    const [modalIsOpen, setModalOpen] = useState();
    const handleClose = () => {
        setModalOpen(false);
    };
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    return (
        <div className={styles['TextBlock']}>
            {(!!heading ||
                !!preamble ||
                !!mainBody ||
                !!blockButtonLink?.url ||
                !!contact ||
                !!links?.length) && (
                <article className={styles['TextBlock__Grid']}>
                    {!!heading && (
                        <h2 className={styles['TextBlock__Title']}>
                            {heading}
                        </h2>
                    )}

                    {!!preamble && (
                        <p className={styles['TextBlock__Preamble']}>
                            {preamble}
                        </p>
                    )}

                    {!!mainBody && (
                        <section
                            className={styles['TextBlock__RichTextWrapper']}>
                            <RichText html={mainBody} />
                        </section>
                    )}

                    {!!blockLink?.url && !!blockLink.linkText && (
                        <div className={styles['TextBlock__ButtonWrapper']}>
                            <Button
                                type="tertiary"
                                href={blockLink.url}
                                icon="arrow"
                                text={blockLink.linkText}
                                iconColor="pink"
                            />
                        </div>
                    )}

                    {!!blockButtonLink?.url && !!blockButtonLink.linkText && (
                        <div className={styles['TextBlock__ButtonWrapper']}>
                            <Button
                                type="primary"
                                href={blockButtonLink.url}
                                icon="arrow"
                                text={blockButtonLink.linkText}
                            />
                        </div>
                    )}

                    {!!contact?.title && (
                        <section
                            className={styles['TextBlock__ContactWrapper']}>
                            <Contact {...contact} />
                        </section>
                    )}

                    {!!links?.length && (
                        <section className={styles['TextBlock__LinksSection']}>
                            <div className={styles['TextBlock__LinksWrapper']}>
                                {linksHeading && (
                                    <h3
                                        className={
                                            styles['TextBlock__LinksHeading']
                                        }>
                                        {linksHeading}
                                    </h3>
                                )}
                                <ul className={styles['TextBlock__LinksList']}>
                                    {links.map(({ url, linkText }, index) => (
                                        <li
                                            className={
                                                styles[
                                                    'TextBlock__LinksListItem'
                                                ]
                                            }
                                            key={index}>
                                            <a
                                                href={url}
                                                className={
                                                    styles['TextBlock__Link']
                                                }>
                                                {linkText}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    )}
                </article>
            )}
            <ShareModal
                title={heading}
                isOpen={modalIsOpen}
                handleClose={handleClose}
            />
        </div>
    );
};

TextBlock.propTypes = {
    heading: PropTypes.string,
    preamble: PropTypes.string,
    blockLink: PropTypes.object,
    blockButtonLink: PropTypes.object,
    mainBody: PropTypes.node,
    contact: PropTypes.object,
    linksHeading: PropTypes.string,
    links: PropTypes.array,
};

TextBlock.defaultProps = {
    heading: '',
    preamble: '',
    blockLink: null,
    blockButtonLink: null,
    mainBody: '',
    contact: null,
    linksHeading: '',
    links: [],
};

export default TextBlock;
